import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { BuilderPage, Footer, Header } from './components';


function App() {
  return (
    <Router>
      <div className="layout-wrapper">
        <div className="content">
          <Header />
          <Routes>
            <Route path="/" element={<BuilderPage modelName="landing-page" urlPath="/" />} />
            <Route path="/rail" element={<BuilderPage modelName="page" urlPath="/rail" />} />
            <Route path="/barbering" element={<BuilderPage modelName="barbering" urlPath="/barbering" />} />
            <Route path="/FSK" element={<BuilderPage modelName="FSK" urlPath="/FSK" />} />
            <Route path="/info-tech" element={<BuilderPage modelName="info-tech" urlPath="/info-tech" />} />
            <Route path="/business" element={<BuilderPage modelName="business" urlPath="/business" />} />
            <Route path="/short-courses" element={<BuilderPage modelName="short-courses" urlPath="/courses" />} />
          </Routes>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
