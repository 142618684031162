import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaUser, FaLockOpen, FaBars, FaTimes } from "react-icons/fa";
import { ActiveLink, Logo } from "./";
import "./styles/header.scss";

interface HeaderProps {}

const links = [
  { to: "/", label: "Home" },
  { to: "/rail", label: "Rail" },
  { to: "/barbering", label: "Barbering" },
  { to: "/FSK", label: "FSK" },
  { to: "/info-tech", label: "Info Tech" },
  { to: "/business", label: "Business" },
  { to: "/short-courses", label: "Short Courses" },
];


const Header: React.FC<HeaderProps> = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div>
      <header className="header-container">
        {/* Branding container */}
        <div className="branding-container">
          <p>Training Professionals</p>
        </div>

        {/* Link container for larger screens */}
        <div className="link-container">
          {links.map((link) => (
            <ActiveLink key={link.to} to={link.to} className="link">
              {link.label}
            </ActiveLink>
          ))}
        </div>

        {/* Profile container for larger screens */}
        <div className="profile-container">
          <Link to="/">
            <FaUser className="icon" />
          </Link>
          <FaLockOpen className="icon" onClick={() => console.log("Logout")} />
        </div>

        {/* Hamburger icon for small screens */}
        <div className="hamburger-container" style={{ position: 'relative' }} onClick={toggleMenu}>
          {menuOpen ? <FaTimes className="icon" /> : <FaBars className="icon" />}
          {menuOpen && (
            <div className="dropdown-menu">   
               {links.map((link) => (
                <ActiveLink key={link.to} to={link.to} className="link">
                  {link.label}
                </ActiveLink>
                ))}
                <div className="profile-container-mobile">
                  <FaUser className="icon" onClick={() => console.log("User")}/>
                  <FaLockOpen className="icon" onClick={() => console.log("Logout")} />
                </div>
            </div>
          )}
        </div>
      </header>

      <div className="logo-container" onClick={() => navigate("/")}>
        <Logo type="small" />
      </div>
    </div>
  );
};

export default Header;
